<script setup lang="ts">
import { emailRegex } from 'data/patterns/email'
import { debounce } from 'lib/helpers/debounce'

import type { Newsletter } from '~/types/storyblok/shared-content/newsletter'

interface State {
  email: string
  success: boolean
  loading: boolean
  showErrors: boolean
  errors: {
    invalidEmail: boolean
    requestFailed: boolean
  }
}

interface Props {
  newsletter: Newsletter
  context: 'menu' | 'newsletter-block' | 'popup'
  backgroundColor: string
  textColorPrimary?: string
  textColorSecondary?: string
  waveColor?: string
}
const props = withDefaults(defineProps<Props>(), {
  textColorPrimary: 'var(--text-primary)',
  textColorSecondary: 'var(--text-secondary)',
  waveColor: 'Black',
})

const emit = defineEmits(['accept'])

const storefrontStore = useStorefrontStore()
const gtm = useGTM()

const state = reactive<State>({
  email: '',
  success: false,
  loading: false,
  showErrors: false,
  errors: {
    invalidEmail: false,
    requestFailed: false,
  },
})

function onBtnClick() {
  emit('accept')
  state.email = ''
  if (props.context === 'menu' || props.context === 'newsletter-block')
    state.success = false
}
function retry() {
  state.success = false
  state.errors.requestFailed = false
  state.email = ''
}
const onInput = debounce(() => {
  if (emailRegex.test(state.email))
    state.errors.invalidEmail = false
  if (!state.errors.requestFailed && !state.errors.invalidEmail)
    state.showErrors = false
}, 500)

function onFocus() {
  state.errors.requestFailed = false
  if (!state.errors.requestFailed && !state.errors.invalidEmail)
    state.showErrors = false
}

async function signup() {
  if (emailRegex.test(state.email)) {
    state.loading = true
    state.errors.invalidEmail = false
    if (!state.errors.requestFailed && !state.errors.invalidEmail)
      state.showErrors = false
    await registerEmail()
    state.loading = false
  }
  else {
    state.showErrors = true
    state.errors.invalidEmail = true
  }
}

async function registerEmail() {
  const { error } = await useFetch('/api/newsletter', {
    method: 'POST',
    body: {
      store: storefrontStore.currentStorefrontCode,
      email: state.email,
      countryCode: storefrontStore.currentMarketCountryCode,
    },
  })

  if (!error.value) {
    state.success = true
    const action = { type: 'registered', time: new Date() }
    localStorage.setItem('newsletterInteraction', JSON.stringify(action))
  }
  else {
    state.showErrors = true
    state.errors.requestFailed = true
  }

  gtm.pushNewsletterEvent({
    eventLabel: state.success ? 'success' : 'fail',
    email: state.email,
    signupSource: props.context === 'newsletter-block' ? 'page' : props.context,
    identifier: 'regular-newsletter',
  })
  return Promise.resolve()
}

const successMessageContent = useRichtext(computed(() => props.newsletter.successMessage))
const errorMessageContent = useRichtext(computed(() => props.newsletter.errorMessage))
const legalMessageContent = useRichtext(computed(() => props.newsletter.legal))

const isMenu = computed(() => (props.context === 'menu'))

const { bgInvertedColor } = useBackgroundInvertedColor(props.backgroundColor)
</script>

<template>
  <div class="newsletter" aria-labelledby="newsletter">
    <div v-if="isMenu" class="edge" />
    <div :class="[context]">
      <span v-if="isMenu" class="title">{{ $t('newsletter') }}</span>
      <div v-if="state.success" class="dialog-container" data-test="newsletter-success-dialog">
        <ModalBasicDialog
          title-key="welcome"
          :primary-color="textColorPrimary"
          :secondary-color="backgroundColor"
          :richtext-message="successMessageContent"
          :context="context"
          @handle-button="onBtnClick"
        />
      </div>
      <div
        v-if="state.errors.requestFailed"
        class="dialog-container"
        data-test="newsletter-error-dialog"
      >
        <ModalBasicDialog
          title-key="anErrorOcurred"
          :primary-color="textColorPrimary"
          :secondary-color="backgroundColor"
          :richtext-message="errorMessageContent"
          :context="context"
          btn-label-key="retry"
          @handle-button="retry"
        />
      </div>

      <div
        v-if="!state.success && !state.errors.requestFailed"
        :class="[{ container: isMenu }]"
        :style="isMenu ? 'text-align: justify' : 'text-align: center'"
      >
        <div class="block">
          <h2
            v-if="context !== 'menu'"
            id="newsletter-title"
            class="main-text"
            data-test="newsletter-register-title"
          >
            {{ newsletter.title }}
        </h2>
          <p class="sub-text" data-test="newsletter-text">
            {{ newsletter.subtitle }}
          </p>
        </div>
        <div class="block">
          <form @submit.prevent="signup">
            <input
              v-model.trim="state.email"
              autocomplete="off"
              :disabled="state.loading || state.success"
              type="email"
              name="email"
              data-test="newsletter-input"
              :placeholder="$t('newsletterBoxEmailPlaceholder')"
              @input="onInput"
              @focus="onFocus"
            >
            <button
              class="signup"
              :style="{ 'background-image': `url('/icons/wavyLine${waveColor}.svg')` }"
              data-test="newsletter-submit"
              :disabled="state.loading"
            >
              {{ newsletter.button }}
            </button>
          </form>
          <p
            v-show="state.errors.invalidEmail"
            class="email-error-message"
            data-test="newsletter-invalid-input"
          >
            {{ $t('invalidEmail') }}
          </p>
          <div v-if="legalMessageContent" class="legal-text" v-html="legalMessageContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/rules/edges';
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';

.newsletter {
  background-color: v-bind(backgroundColor);
  color: v-bind(textColorPrimary);
}

.newsletter .menu {
  padding: 2rem;
  padding-top: 0rem;
}

.edge {
  @include teeth;
  position: relative;
  top: -0.7rem;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background-color: var(--pink);
  border: none;
}

.title {
  @include caption1;
  margin: 1rem 0;
  color: v-bind(textColorSecondary);
}

.main-text {
  @include h6;
  color: inherit;
  line-height: 1.5;
}

.sub-text {
  @include body2;
  color: v-bind(textColorPrimary);
  margin-top: 0.5rem;
  margin-bottom: 1.6rem;
}

.newsletter-block .main-text {
  @include h5;
  color: inherit;
  margin: 0 2.2rem;

  @media (min-width: $tablet) {
    @include h4;
    color: inherit;
  }
}
.newsletter-block .sub-text {
  @include body2;
  color: inherit;
  margin-top: 1.8rem;

  @media (min-width: $tablet) {
    @include body1;
    color: inherit;
    margin-top: 1.8rem;
  }
}

form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

input {
  @include inputText;
  width: 80%;
  padding: 0.5rem 0;
  color: v-bind(textColorPrimary);
  background-color: transparent;
  border-bottom: 1px v-bind(textColorPrimary) solid;
  font-size: 1.6rem;
  &::placeholder {
    color: v-bind(textColorSecondary);
    background-color: transparent;
    font-size: 1.6rem;
  }
  &:focus-visible {
    outline: 2px solid v-bind(bgInvertedColor);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px v-bind(backgroundColor) inset;
  -webkit-text-fill-color: v-bind(textColorPrimary);
}

.legal-text {
  @include caption1;
  color: inherit;
  margin-top: 1.5rem;
  &:deep(a:focus-visible) {
    outline: 2px solid v-bind(bgInvertedColor);
  }
}

button.signup {
  @include body2;
  color: inherit;
  width: 25%;
  padding: 0.7rem 0;
  position: relative;
  background-size: 20rem;
  background-repeat: repeat-x;
  background-position-y: bottom;
  &:focus-visible {
    outline: 2px solid v-bind(bgInvertedColor);
  }

  @media (min-width: $tablet) {
    padding: 0.6rem 0;
  }
}

.email-error-message {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: v-bind(textColorSecondary);
  text-align: left;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  line-height: 1.5;
}

.menu .dialog-container {
  text-align: left;
  align-items: flex-start;
}

.error-title {
  color: inherit;
  font-weight: bold;
}

.block {
  margin-bottom: 2rem;
}

@media (min-width: $large-breakpoint) {
  .container {
    display: flex;
    flex-direction: row;
    gap: 10%;
  }

  .main-text {
    @include h5;
    color: inherit;
  }

  input {
    font-size: 1.4rem;
    &::placeholder {
      font-size: 1.4rem;
    }
  }
}
</style>
